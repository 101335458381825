import React, { useContext } from "react";
import { MenuContext } from "../../context";

import { Link } from "gatsby";

import ContactSvg from "../../assets/contact.inline.svg";
import NewWindow from "../../assets/new_window.inline.svg";
import PhoneSvg from "../../assets/phone.inline.svg";

const LiveChat = () => {
  const menuContext = useContext(MenuContext);
  return (
    <section className="live-chat">
      <Link
        to="https://www.hsbc.co.uk/mortgages/intermediaries/help/"
        target="_blank"
        className="general-anchor-btn live-chat-btn"
      >
        Chat with us
        <NewWindow />
      </Link>
      <div className="description">
        <ContactSvg />
        <p>
          Our Chat facility gives you quick access to a member of our experienced UK based Broker Support Team who will
          be happy to provide assistance with all your queries.
        </p>
      </div>
      <div className="row">
        <div className="contact-us col-xs-12 col-md-12 col-xl-6">
          <h3>Please contact us on</h3>
          <address>
            <a href="tel:03456005847" inert={menuContext.isMenuOpen ? "true" : null}>
              0345 600 5847
            </a>
          </address>
          <p className="date">
            Monday - Friday, <time datatype="9:00">9am</time> - <time dateTime="17:00">5pm</time>
          </p>
          <ul>
            <li>
              <p>
                <PhoneSvg />
                <strong>Press 1</strong> if you are a customer calling to pay a fee
              </p>
            </li>
            <li>
              <p>
                <PhoneSvg />
                <strong>Press 2</strong> for policy and criteria questions
              </p>
            </li>
            <li>
              <p>
                <PhoneSvg />
                <strong>Press 3</strong> for assistance with keying a new application
              </p>
            </li>
            <li>
              <p>
                <PhoneSvg />
                <strong>Press 4</strong> for case updates or queries relating to valuations / solicitors
              </p>
            </li>
            <li>
              <p>
                <PhoneSvg />
                <strong>Press 5</strong> to contact our High Value Mortgage Service for applications over £1m
              </p>
            </li>
          </ul>
        </div>
        <div className="solicitor-section col-xs-12 col-md-12 col-xl-6">
          <h3>Solicitors / Conveyancers only</h3>
          <p>
            If you are a Solicitor / Conveyancer and have an urgent query, you can call us on:<br></br>0370 6007722.
          </p>
          <p>
            Please note that we will only be able to verify Solicitor / Conveyancer telephone calls to this number
            following a written request. Please refer to the criteria section{" "}
            <a title="Solicitors/Conveyancers only" href="/criteria/?p=solicitors-conveyancers-only">
              Solicitors / Conveyancers only
            </a>{" "}
            for more information.
          </p>
          <p>
            <strong>We are not able to deal with any broker enquiries via this telephone number.</strong>
          </p>
        </div>
      </div>
    </section>
  );
};

export default LiveChat;
